html {
  overflow-x: hidden;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
body {
  /* background: linear-gradient(#dddddd, #ffffff); */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Amatic SC", cursive;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn-normal {
  background-color: #14aecf;
  border: none;
  color: white;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 2em;
  transition: 0.3s;
  max-width: max-content;
  padding-right: 7em;
  padding-left: 7em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 20px;
}
.btn-normal:hover {
  background-color: #52c1da;
}
@font-face {
  font-family: "Assistant";
  font-style: normal;
  font-weight: normal;
  src: local("Assistant-Regular"),
    url("../fonts/Assistant/Assistant-Regular.ttf") format("ttf");
}

.hebrew {
  font-family: "Rubik" !important;
}
