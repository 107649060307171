@import url("https://fonts.googleapis.com/css2?family=Rokkitt&display=swap");

#hp-college-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f5f5f5;

  padding-top: 2%;
  padding-bottom: 5%;
}

#hp-college-container .title {
  font-size: 3vw;
  padding: 1em;
}

#hp-college-container .graphology-basics-container {
  background-image: url("../../../media/img/HomePage/graphology-basics.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#hp-college-container .graphology-basics-container-back {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../../../media/img/HomePage/graphology-basics.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2vw;
  padding-top: 3%;
}

#hp-college-container .flippy-front {
  padding: 0px !important;
}

#hp-college-container .flippy-back {
  padding: 0px !important;
}

#hp-college-container .course-footer-container {
  background-color: rgba(136, 136, 136, 0.568);
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}

#hp-college-container .course-footer-title {
  font-family: "Rokkitt", serif;
  font-size: 3vw;
  font-weight: 400;
}
.college-graphology-h2 {
  font-family: "Nunito";
  text-align: "center";
  font-size: 4vw !important;
  padding: 1em;
}
#hp-college-container .course-back-title {
  text-align: center;
  font-size: 2vw;
  padding-left: 10%;
  padding-right: 10%;
}

#hp-college-container .course-back-content {
  font-family: "Rokkitt", serif;
  text-align: center;
  font-size: 1.5vw;
  max-width: 95%;
}

#hp-college-container .course-back-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#hp-college-container .course-back-btn-container {
  display: flex;
  flex-direction: column;
}
#hp-college-container .course-back-btn:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}
#hp-college-container .course-back-btn {
  transition: 0.3s;
  align-self: flex-end;
  margin-right: 2%;

  width: 10vw;
  border: 1px solid black;
  font-family: Nunito;
  align-self: center;
  font-size: 1.5vw;
  width: 80%;
  color: white;
  background-color: black;
  padding: 0.5em;
}

#hp-college-container .flippy-cards-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 5%;
  padding-right: 5%;
  gap: 4vw 4vw;
}

#hp-college-container .intuition-container {
  background-image: url("../../../media/img/HomePage/Intuition.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#hp-college-container .intuition-container-back {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../../../media/img/HomePage/Intuition.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 3%;
  padding-bottom: 2vw;
}

#hp-college-container .drawing-container {
  background-image: url("../../../media/img/HomePage/drawing-analysis.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#hp-college-container .drawing-container-back {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../../../media/img/HomePage/drawing-analysis.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2vw;

  padding-top: 3%;
}

#hp-college-container .advanced-container {
  background-image: url("../../../media/img/HomePage/advanced-graphology.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#hp-college-container .advanced-container-back {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../../../media/img/HomePage/advanced-graphology.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 30vw;
  height: 19vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2vw;

  padding-top: 3%;
}
.conteiner {
  width: 40vw !important;

  height: 25vw !important;
}
#course-basics {
  transform: translateY(1.5vw);
}
#course-intuition {
  transform: translateY(3vw);
}
#course-drawing {
  transform: translateY(1.5vw);
}
#course-advanced {
  transform: translateY(1.5vw);
}
@media (max-width: 990px) {
  .conteiner {
    width: 90vw !important;

    height: 50vw !important;
  }
  #hp-college-container .course-back-title {
    font-size: 4vw;
  }
  #hp-college-container .flippy-cards-container {
    width: 100%;
    display: flex;
    padding-left: 0%;
    padding-right: 0%;
    flex-direction: column;
  }
  .college-graphology-h2 {
    font-size: 7vw !important;
    text-align: center;
  }
  #hp-college-container .course-footer-title {
    font-family: "Rokkitt", serif;
    font-size: 6vw;
    font-weight: 400;
  }
  #hp-college-container .course-back-content {
    font-size: 3.5vw;
    font-weight: 400;
  }
  #hp-college-container .course-back-btn {
    align-self: center;
    font-size: 3.5vw;
    width: 80%;
    border: none;
    color: white;
    background-color: black;
    padding: 2vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    max-height: 8vw;
  }
  #course-basics {
    transform: translateY(2.5vw);
  }
  #course-intuition {
    font-size: 3.8vw !important;

    transform: translateY(3vw);
  }
  #course-drawing {
    transform: translateY(1.5vw);
  }
  #course-advanced {
    font-size: 3.8vw !important;
  }
}
