@font-face {
  font-family: "Antipasto Pro Regular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Regular"),
    url("AntipastoPro_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Hairline";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Hairline"),
    url("AntipastoPro-Hairline_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Thin";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Thin"),
    url("AntipastoPro-Thin_trial 2.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Thin";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Thin"),
    url("AntipastoPro-Thin_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Extralight";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Extralight"),
    url("AntipastoPro-Extralight_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Light";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Light"),
    url("AntipastoPro-Light_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Medium";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Medium"),
    url("AntipastoPro-Medium_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro DemiBold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro DemiBold"),
    url("AntipastoPro-DemiBold_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro Bold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro Bold"),
    url("AntipastoPro-Bold_trial.woff") format("woff");
}

@font-face {
  font-family: "Antipasto Pro ExtraBold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Antipasto Pro ExtraBold"),
    url("AntipastoPro-ExtraBold_trial.woff") format("woff");
}
