.contact-details-container {
  display: flex;
  flex-direction: row;
  padding-top: 5vw !important;
  z-index: 2;
  background-color: white;
}

.contact-details-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  margin-left: 50px;
  font-size: 1.6vw;
}

.contact-details-info-container {
  display: flex;
}
.contact-footer-social-icons-container {
  display: flex;
  font-size: 2vw;
}
.contact-footer-social-icons-container a {
  color: black;
}
.contact-footer-social-icons-container.hebrew {
  justify-content: right;
  flex-direction: row-reverse;
}
.contact-details-container {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.contact-details-info-part h3 {
  font-size: 2vw;
  padding-bottom: 1em;
}
.contact-details-googlemaps-container {
  width: 60%;
}
.contact-details-googlemaps-container iframe {
  width: 100%;
}
.contact-details-info-container.hebrew {
  text-align: right;
}
@media (max-width: 990px) {
  .contact-details-container {
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    gap: 5vw;
  }
  .contact-details-info-container.hebrew {
    text-align: center;
  }
  .contact-details-info-part {
    padding: 1vw;
    transform: translateX(-5vw) !important;
  }
  .contact-details-info-container {
    flex-direction: column;
    gap: 2vw;
    align-items: center;
  }
  .contact-details-info {
    font-size: 3vw;
  }
  .contact-details-info-part h3 {
    font-size: 4vw;
    text-align: center;
  }
  .contact-footer-social-icons-container {
    font-size: 5vw;
    justify-content: center;
  }
  .contact-details-googlemaps-container {
    width: 90%;
  }
}
