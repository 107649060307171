.written-title {
  text-align: center;
  padding: 2em;
  font-size: 2.5vw;
}
.about-title-written.hebrew {
  text-align: right;
}
.about-p-written.hebrew {
  text-align: right;
}
.written-title mark {
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 90%, #e26565 0%);
  background-position: 0% 1.35em; */
  background: rgba(255, 255, 255, 0);
  font-size: 3vw;
  font-family: Nunito;
  font-weight: 1000;
}
.written-about-container {
  display: flex;
  padding-bottom: 4vw;
}
.about-text {
  width: 50%;
  padding-left: 5%;
  padding-right: 5%;
}
.about-text-bottom {
  width: 100%;
  padding-left: 5%;
  text-align: center;
  padding-right: 5%;
}
.about-title-written {
  font-family: "Special Elite", cursive;
  font-weight: 900;
  padding-bottom: 1em;
  padding-top: 1em;
  font-size: 1.6vw;
}
.about-title-maktubmishamaim {
  font-family: "Special Elite", cursive;
  font-weight: 900;
  padding-left: 3em;
  padding-right: 3em;
  text-align: center;
  padding-top: 0em !important;
  font-size: 1.6vw;
}
.written-logo {
  width: 50%;
}
.written-logo.hebrew > img {
  transform: translateX(7vw);
}
.about-p-written {
  /* text-align: center; */
  /* padding: 0.6em; */
  font-size: 1.2vw;
  font-family: "Special Elite", cursive;
  text-align: justify;
}
.written-bottom-container {
  margin: 1vw;
  margin-bottom: 0vw;
  padding-bottom: 3vw;
}
.written-page-section {
  background-color: white;
  margin: auto;
  height: fit-content;
  width: 80%;
  -webkit-box-shadow: 0px 0px 0px 2px #00000049, 0px 0px 0px 4px #4b4c4b49,
    0px 0px 0px 6px #82848249, 0px 0px 0px 8px #b2b5b249,
    0px 0px 0px 10px #daddda49, 5px 5px 20px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 2px #00000049, 0px 0px 0px 4px #4b4c4b49,
    0px 0px 0px 6px #82848249, 0px 0px 0px 8px #b2b5b249,
    0px 0px 0px 10px #daddda49, 5px 5px 20px 5px rgba(0, 0, 0, 0);
}
.heart {
  display: flex;
  justify-content: center;
}
.heart img {
  padding: 1vw;
  margin: 1vw;
  width: 15%;
  bottom: 0em;
  max-height: 14vw;
  position: relative;
  transition: 0.3s;
}
.heart img:hover {
  /* bottom: 1em; */
  transform: scale(0.97);
  filter: brightness(1.6) saturate(0.8);
}
.heart img:active {
  transition: 0.1s;
  transform: scale(0.9);
}
@media (max-width: 990px) {
  .written-title {
    font-size: 5vw;
  }
  .written-title mark {
    font-size: 6vw;
    width: 100%;
  }
  .written-about-container {
    flex-direction: column;
    align-items: center;
    gap: 5vw;
  }
  .about-text {
    width: 100%;
  }
  .about-text h4 {
    font-size: 4.5vw;
  }
  .about-text p {
    font-size: 4vw;
  }
  .written-logo {
    width: 90%;
  }
  .about-title-written {
    font-size: 4.5vw;
  }
  .about-text-bottom p {
    font-size: 4vw;
  }
  .about-title-maktubmishamaim {
    font-size: 4vw;
    padding-left: 1em;
    padding-right: 1em;
  }
  .about-p-written {
    font-size: 3.5vw;
  }
  .written-page-section {
    width: 98%;
  }
  .written-logo.hebrew > img {
    transform: translateX(0vw);
  }
}
