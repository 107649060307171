@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

#intuition-course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#intuition-course-container .course-title {
  padding-top: 4vw;
  font-size: 3.5vw;
  font-family: "Antipasto Pro Regular";
  text-align: center;
  padding-bottom: 4vw;
  margin-bottom: 0px !important;
}
#intuition-course-container .course-ul.hebrew {
  padding-right: 0%;
}
#intuition-course-container .course-img {
  width: 30vw;
  height: auto;
  padding-bottom: 3vw;
}

#intuition-course-container .course-text-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  text-align: justify;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

#intuition-course-container .course-ul {
  font-size: 1.25vw;

  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

/* @media only screen and (max-width: 700px) {
  #intuition-course-container .course-ul > li:not(:last-child) {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  #intuition-course-container .course-ul > li {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  #intuition-course-container .course-ul {
    margin-left: 0px !important;
  }
}
 */
@media only screen and (max-width: 990px) {
  #intuition-course-container .course-ul > li:not(:last-child) {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  #intuition-course-container .course-ul > li {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  #intuition-course-container .course-ul {
    margin-left: 0px !important;
  }

  #intuition-course-container .course-text-container {
    width: 80vw;
    padding-bottom: 5vw;
  }

  #intuition-course-container .course-title {
    font-size: 6vw;
  }

  #intuition-course-container .course-img {
    width: 60vw;
    height: auto;
    padding-bottom: 6vw;
  }

  #intuition-course-container .course-text {
    font-size: 3vw !important;
  }

  #intuition-course-container .course-ul > li {
    font-size: 3vw;
    margin-top: 5vw !important;
    line-height: 5vw !important;
  }
}

#intuition-course-container .course-ul > li {
  padding-left: 1vw;
  line-height: 1.5vw;
  margin-bottom: 1vw !important;
}

#intuition-course-container .course-ul > li:not(:last-child) {
  padding-bottom: 0.5vw;
}

#intuition-course-container .course-text {
  font-size: 1.25vw;
}

#intuition-course-container .padding-bot-s {
  padding-bottom: 1vw;
}

#intuition-course-container .end-section-text {
  padding-top: 2vw;
  font-size: 1.5vw;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-align: center;
  padding-bottom: 1vw;
}

#intuition-course-container .course-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  padding-bottom: 2vw;
}

#intuition-course-container .course-btn-container > button {
  color: #ffffff;
  background-color: black;
  border: 1px solid black;
  font-size: 1.125vw;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  width: 15vw;
}
