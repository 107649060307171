.modal {
  /* width: 150vw !important; */
  /* left: 10%; */
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog {
  width: max-content;
}
.video-modal-title {
  color: rgb(134, 134, 134) !important;
  font-size: 1vw;
  font-family: Rubik;
}
.modal-header {
  border-bottom: none;
  padding-bottom: 1vw;
  padding-right: 0px;
  padding-left: 0px;
}
.modal-content {
  width: 100%;
  height: max-content;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.modal-body {
  overflow-y: visible;
  height: fit-content !important;
  padding: 0px !important;
}
.modal-fullscreen .modal-content {
  height: fit-content;
}
.modal-fullscreen .modal-body {
  height: 100% !important;
  width: 100%;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.normal-size-video {
  height: auto;
  width: 40vw;
}

.mobile-video {
  height: auto;
  width: 18vw;
}
.modal-title-container {
  top: 1.1vw;
  position: relative;
  background-color: rgba(0, 0, 0, 0.103);
  width: 100%;
  display: flex;
}
.modal-close-btn {
  color: white;
  transition: 0.3s;
  font-size: 1vw;
  padding: 0.2em;
}
.modal-close-btn:hover {
  color: rgb(102, 102, 102);
}
@media (max-width: 990px) {
  .modal-close-btn {
    font-size: 3vw;
  }

  .modal-fullscreen .modal-body {
    height: auto;
    overflow-y: visible;
  }

  .mobile-video {
    height: auto;
    width: 45vw;
  }

  .normal-size-video {
    height: auto;
    width: 80vw;
  }
  .title-profile-name {
    font-size: 4vw !important;
  }
  .title-profile-desc {
    font-size: 3vw !important;
  }
  .profile-pic {
    width: 15vw !important;
    height: 15vw !important;
  }
  .profile-pic-mobile {
    width: 15vw !important;
    height: 15vw !important;
  }
  .title-profile-name-mobile {
    font-size: 3.5vw !important;
  }

  .title-profile-desc-mobile {
    font-size: 2.5vw !important;
  }
  .title-profile-text-container-mobile {
    padding-left: 1vw !important;
  }
  .youtube-vid-iframe {
    width: 700vw !important;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    /* width: 100% !important; */
    margin: 30px auto;
  }
}

.title-profile-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.title-profile-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1vw;
  padding-left: 1vw;
  color: #ffffff;
}

.title-profile-text-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.5vw;
  padding-left: 0.5vw;
  color: #ffffff;
}

.title-profile-name {
  font-size: 2vw;
}

.title-profile-desc {
}

.title-profile-name-mobile {
  font-size: 1.5vw;
}

.title-profile-desc-mobile {
  font-size: 1vw;
}

.profile-pic {
  width: 10vw;
  height: 10vw;
}

.profile-pic-mobile {
  width: 5vw;
  height: 5vw;
}
