#hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.32);
}
#hero-container.hebrew {
  flex-direction: row-reverse;
}
#hero-container.english {
  flex-direction: row;
}
#hero-container .main-logo {
  width: 35vw;
  height: auto;
}

#hero-container .college-logo {
  width: 25vw;
  height: auto;
}
#hero-container .logo-title {
  font-size: 5vw;
  height: auto;
  text-align: center;
  max-width: fit-content;
  padding: 1vw;
  font-family: Nunito;
}
#hero-container .logo-title.hebrew {
  font-family: Rubik;
}
@media (max-width: 990px) {
  #hero-container .main-logo {
    display: none;
  }
  #hero-container .logo-title {
    font-size: 8vw;
    max-width: 100%;
  }
}
