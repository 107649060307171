#institute-page-container {
  padding-top: 3%;
  padding-bottom: 3%;
  background-color: #ffffff;
}

#institute-page-container .section-image {
  width: 27vw;
  height: auto;
  transition: 0.3s;
}

#institute-page-container .institute-page-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
}

#institute-page-container .section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#institute-page-container .section-container:hover {
  cursor: pointer;
}
#institute-page-container .section-container:not(:last-child) {
  margin-bottom: 5%;
}

#institute-page-container .institute-title {
  font-size: 3vw;
  padding-bottom: 5%;
  font-weight: 400 !important;
}

#institute-page-container .institute-content {
  text-align: center;
  font-weight: 300;
  font-size: 1.5vw;
  line-height: 3.5vh;
  padding-bottom: 5%;
  max-width: 50vw;
}
#institute-page-container .section-image:hover {
  transform: scale(1.1);
  filter: brightness(1.2) contrast(0.8);
}
@media (max-width: 990px) {
  #institute-description-container .content {
    font-size: 3vw;
  }
  #institute-page-container .institute-title {
    font-size: 5vw;
  }
  #institute-page-container .institute-content {
    font-size: 3vw;
    line-height: 1.5em;
  }
  #institute-page-container .section-image {
    width: 50vw;
  }
}
