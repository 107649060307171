.about-section h3 {
  font-size: 3vw;
  text-align: center;
  font-family: Nunito;
  font-weight: 600;
  padding: 1em;
}
.about-section .about-p-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-section .about-p-container.hebrew > p {
  font-family: Rubik;
  text-align: right;
}
.instagram-div {
  text-align: center;
  padding: 0em;
  height: 5vw;
  width: 5vw;
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  border-radius: 1.1vw;
  margin-top: 3.5vw;
  margin-left: 1.7vw;
}
.instagram-div i {
  padding: 0%;
  position: relative;
  bottom: 1.6vw;
}
.about-section .youtube-iframe-about-container {
  display: flex;

  justify-content: center;
}
.about-channels-container-child {
  background-color: white;
  border-radius: 1vw;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    0px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 0px 5px 15px 5px rgba(0, 0, 0, 0);
}
.about-channels-container-child .channels {
  display: flex;
  justify-content: center;
  font-size: 5vw;
}
.about-channels-container {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 242, 240, 0) 30%,
    rgba(237, 236, 233, 1) 30%,
    rgba(247, 247, 246, 1) 100%
  );
  /* background-position: 0% 50%; */
  width: 70%;
  position: relative;
  left: calc(15vw);
  padding: 6vw;
}
.about-section p {
  text-align: justify;
  width: 90vw;
  font-family: Nunito;
  font-size: 1.7vw;
  padding-top: 1em;
}
.about-me {
  display: flex;
  justify-content: center;
  margin: 5%;
  /* margin-bottom: 0% !important; */
  margin-top: -2%;
  font-family: "Lekton", sans-serif;
}
.about-title {
  margin-top: 3em;
  text-align: justify;
  font-size: 50px;
  margin-bottom: 1em;
  width: max-content;
}
.about-p {
  font-size: 21px;
  text-align: justify;
  font-family: "Special Elite", cursive;
}

.about-p:not(:last-child) {
  margin-bottom: 50px;
}
.about-p-he {
  font-size: 18px;
  text-align: right;
}
.about-img {
  position: relative;
  top: 30%;
  width: 40%;
  height: fit-content;
  margin-left: 2%;
  margin-right: 2%;
}

.content-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.fade-text {
  --webkit-mask-image: linear-gradient(180deg, #fff 1%, transparent);
}

.scroll-y {
  overflow-y: scroll;
  height: 400px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-y::-webkit-scrollbar {
  display: none;
}
.about-you-slide img {
  height: 40vw;
  width: fit-content;
  position: relative;
  z-index: -1;
  left: 35vw;
  overflow: hidden;
  top: 5vw;
  -webkit-box-shadow: inset -1px 3px 8px 5px #1f87ff, 2px 5px 16px 0px #0b325e,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: inset -1px 3px 8px 5px #1f87ff, 2px 5px 16px 0px #0b325e,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}
.about-you-slide {
  height: 50vw;
}
.carousel-indicators {
  left: 5vw !important;
}
#about-you h2 {
  font-size: 5vw;
  padding: 0.5em;
  padding-bottom: 1em;
  text-align: center;
  font-family: Nunito;
}
@media (max-width: 990px) {
  #about-you h2 {
    font-size: 8vw;
  }
  .about-section h3 {
    font-size: 6vw;
  }
  .about-section p {
    font-size: 3.5vw;
  }
  .youtube-iframe-about {
    width: 90% !important;
    height: 400px !important;
  }
  .about-bottom-pic {
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: red !important; */
  }

  .channels .fa-instagram {
    font-size: 4.3vw !important;
  }
  /* .instagram-channel {
    font-size: 5vw !important;
    background: #f58529 !important;
    background: -webkit-radial-gradient(
      circle farthest-corner at bottom left,
      #f58529 0%,
      #dd2a7b 74%,
      #515bd4 87%
    ) !important;
    background: -moz-radial-gradient(
      circle farthest-corner at bottom left,
      #f58529 0%,
      #dd2a7b 74%,
      #515bd4 87%
    ) !important;
    background: radial-gradient(
      circle farthest-corner at bottom left,
      #f58529 0%,
      #dd2a7b 74%,
      #515bd4 87%
    ) !important;
    color: transparent !important;
    --webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  } */
  .instagram-i::before {
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    background: -webkit-radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    display: initial;
  }
  .fa-gradient {
    background: --webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f00),
      to(#333)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .about-you-slide img {
    height: 100%;
    width: 90%;
    left: 5vw;
    /* top: 13vw; */
    transform: scale(0.9);
  }
  .about-you-slide {
    height: fit-content;
  }
}
