#tvSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tv-title {
  font-size: 2.7vw;
  text-align: center;
  font-family: Nunito;
  width: 80%;
  padding: 1.5vw;
}
.tv-container {
  padding-top: 3em;
  padding-bottom: 5em;
  display: flex;
  justify-content: center;
  position: relative;
  width: 60%;
}
.tv-img {
  /* padding-bottom: 5em; */
  display: block;
  height: auto;
  transition: 0.3s;
  object-fit: contain;
}
.tv-container:hover .overlay {
  width: 100%;
  left: 0;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  background-color: #000000;
  overflow: hidden;
  width: 0;
  height: 30%;
  transition: 0.5s ease;
}
.text {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.tv-img:hover {
  background-position: 10%;
}
.text-inside {
  bottom: 0em;
  position: relative;
  color: rgb(207, 12, 12);
  transition: 0.3s;
}
.text-inside:hover {
  transform: scale(1.01);
  bottom: 0.1em;
  color: white;
}
.text-inside:active {
  color: rgb(211, 127, 127);

  transition: 0.1s;
  bottom: 0.08em;
}
@media (max-width: 990px) {
  .tv-title {
    font-size: 5vw;
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
  }
  .tv-container {
    width: 90%;
  }
}
