.contact-form-container {
  /* margin-bottom: 5vw; */
  z-index: 2;
}
.contact-form {
  z-index: 2 !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  /* -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.089);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.116); */
  padding-top: 4em;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 14.7vh;
  height: 39.6vw;
  /* margin-bottom: 2em; */
  /* filter: brightness(0.4); */
}

.contact-form-title {
  font-family: Nunito;
  font-size: 3.3vw;
  max-width: 80%;
}

.contact-form-content-container {
  display: flex;
  justify-content: center;
}

.contact-form-image {
  height: 29vw;
  position: absolute;
  max-width: 40%;
  left: 15vw;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: saturate(0.8) contrast(0.95) brightness(1.07);
}

.contact-form-fields {
  display: flex;
  flex-direction: column;
  padding-top: 8em;
  width: 50%;
  left: 20vw;
  position: relative;
}
.contact-form-fields .submit-btn {
  width: 100%;
  font-family: Nunito;
}
.title-container-contact {
  display: flex;
  justify-content: center;
}
.contact-form-fields .form-select {
  width: 60%;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  /* padding-left: 0.5em;
  padding-right: 0.5em; */
  font-family: Nunito;
  font-size: 1.2vw;
  border-width: 1px;
  border-radius: 10px !important;
  border: 1px solid black;
  /* margin-bottom: 1em; */
}
.contact-form-input {
  width: 60%;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: Nunito;
  font-size: 1.2vw;
  border-width: 1px;
  border-radius: 10px !important;

  margin-bottom: 1em;
}
.contact-form-input.hebrew {
  text-align: right;
}
.contact-form-input:focus {
  outline-width: 0px;
  outline: none;
  box-shadow: none !important;
  border-color: gray !important;
}

.contact-form-input-message {
  height: 4em;
  resize: none;
  border: 1px solid #363636;
  margin-bottom: 2em;
}

.btn-normal {
  background-color: #000000;
  border: none;
  color: white;
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding: 3%;
  transition: 0.3s;
  max-width: 80%;
  padding-right: 7em;
  padding-left: 7em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.form-btn {
  max-width: 60%;
}
.btn-normal:hover {
  background-color: #52c1da;
}
@media (max-width: 990px) {
  .contact-form {
    /* padding-bottom: 250vh; */
    height: fit-content;
    padding-bottom: 0;
  }
  .contact-form-content-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .contact-form-image {
    position: relative;
    left: 0;
    top: 5vw;
    /* z-index: -1; */
    padding-top: 5vw;
    /* height: 65%; */
    /* max-width: 70%; */
    height: 70vh !important;
    max-width: 100% !important;
  }
  .contact-form-input {
    font-size: 5vw;
    /* width: 500; */
  }
  .contact-form-fields .form-select {
    font-size: 5vw !important;
    width: 100%;
  }
  .contact-form-title {
    font-size: 5vw;
  }
  .contact-form-fields {
    width: 100%;
    align-items: center;
    left: 0;
  }
  .contact-form-fields input {
    margin-bottom: 3vw !important;
    width: 100%;
  }
  .contact-form-fields textarea {
    margin-bottom: 3vw !important;
    width: 100%;
  }
  .contact-form-fields button {
    margin-bottom: 3vw !important;
    min-width: 100% !important;
  }
}
