#reactions-section .carousels-container {
  background-color: rgb(247, 247, 247);
}
/* .reactions-options {
} */
.reactions-options button {
  color: white;
  background-color: black;
  font-family: Nunito;
  font-size: 2vw;
  width: 10vw;
  margin: 0.7vw;
  transition: 0.3s;
  border: 1px solid black;
  border-radius: 0.5vw 0.5vw 0.2vw 0.2vw;
  margin-bottom: 0vw;
  margin-top: 2vw;
}
.reactions-options button:hover {
  background-color: rgb(48, 48, 48);
}
.reactions-options .selected:hover {
  background-color: rgb(247, 247, 247);
}
.reactions-options .selected {
  transition: 0.3s;
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(247, 247, 247);
  color: black;
}
.reaction-video-thumbnail {
  display: flex;
  justify-content: center;
}
.reaction-video-thumbnail img {
  z-index: 2;
  padding: 5vw;
  object-fit: contain;
}

.reaction-video-thumbnail img:hover {
  cursor: pointer;
}
.play-button {
  font-size: 150px;
  z-index: 2;
  position: absolute;
  top: 40%;
  transition: 0.3s;
  color: white;
  opacity: 0.7;
}
.img-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.img-gallery img {
  -webkit-box-shadow: 8px 0px 0px 0px #dcd0c0, 0px 8px 0px 0px #adaaa920,
    -8px 0px 0px 0px #4e4e5620, 0px 0px 0px 8px #866d6d40,
    -18px -4px 42px 3px rgba(54, 58, 73, 0);
  box-shadow: 8px 0px 0px 0px #dcd0c0, 0px 8px 0px 0px #adaaa920,
    -8px 0px 0px 0px #4e4e5620, 0px 0px 0px 8px #866d6d40,
    -18px -4px 42px 3px rgba(54, 58, 73, 0);
  width: 30vw;
  height: 30vw;
  margin: 1vw;
  /* max-height: fit-content; */
  object-fit: cover;
}
.play-button:hover {
  opacity: 0.5;
  cursor: pointer;
  transform: scale(1.1);
}
.play-button:active {
  transition: 0.1s;
  transform: scale(1.05);
  opacity: 0.9;
}
.modal-backdrop .show {
  display: none;
}
.reactions-options.hebrew {
  display: flex;
  justify-content: end;
}
.reactions-options.hebrew > button {
  font-family: Rubik;
}
@media (max-width: 990px) {
  .reaction-video-thumbnail img {
    padding: 0vw;
  }
  .img-gallery {
    align-content: flex-start !important;
    align-items: flex-start !important;
    width: 100%;
  }
  .img-gallery img {
    align-self: flex-start !important;
    width: 40%;
  }
  .reactions-options button {
    width: 20vw;
    margin-left: 5vw;
    margin-right: 5vw;
    height: 10vw;
    font-size: 4vw;
  }
  .reactions-options {
    display: flex;
    justify-content: center;
    margin: 2vw;
  }
}
