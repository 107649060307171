#graphology-description-container {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4vh;

  background-color: #ffffff;
}

#graphology-description-container .content {
  padding-right: 5%;
  padding-left: 5%;
  text-align: center;
  font-size: 2vw;
}
@media (max-width: 990px) {
  #graphology-description-container .content {
    font-size: 4vw;
  }
}
