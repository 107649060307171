.cafe-about-container {
  display: flex;
  flex-direction: column;
  padding-top: 5vw;
  /* background: rgb(200, 179, 147);
  background: linear-gradient(
    355deg,
    rgb(230, 205, 169) 0%,
    rgb(223, 205, 164) 36%,
    rgb(235, 219, 183) 100%
  ); */
}
.cafe-about-p {
  padding-top: 5vw;
  padding-bottom: 5vw;
  font-size: 1.4vw;
  text-align: center;
  width: 60vw;
  font-family: "Nunito", sans-serif;
}
.cafe-p-container {
  margin: auto;
  padding: 2vw;
}
.cafe-about-head {
  display: flex;
  justify-content: center;
}
.cafe-about-head img {
  width: 30%;
}
.cafe-about-title {
  font-size: 1.5vw;
  font-family: "Nunito", sans-serif;
}
.cafe-about-title mark {
  /* background: linear-gradient(
    -100deg,
    hsla(0, 0%, 0%, 0.3),
    hsla(0, 0%, 0%, 0.4) 95%,
    hsla(48, 92%, 75%, 0.1)
  );
  border-radius: 1em 0;
  padding: 0.5rem; */
  font-size: 4vw;
  color: #4e4e4e;
  font-family: "Amatic SC", cursive;
  background: none;
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, #000000 0%);
  background-position: 0% 1.4em; */
}
.cafe-text-container {
  width: 40%;
  line-height: 3vw;
  color: #4e4e4e;
  padding: 5vw;
}
/* 
.cafe-text-container > .cafe-about-title {
  font-size: 1.8vw;
  text-align: center;
  width: 60vw;
  font-family: "Nunito", sans-serif;
} */
.cafe-about-title > b {
  font-size: 1.3vw;
  color: black;
}
.cafe-second-block {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  width: 100%;
}
.second-block-text {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 1.25vw;
  padding-left: 2em;
  padding-right: 2em;
  font-family: "Nunito", sans-serif;
}
.second-block-text p {
  width: max-content;
  /* background: linear-gradient(180deg, #55555500 92%, #555555 0%);
  background-position: 0% 3.9em; */
  padding-top: 5%;
  height: 30%;
  /* padding-top: 2em; */
  /* padding: 1vw; */
}
.cafe-sign {
  width: 25%;
  height: 100%;
}
.second-block-text p b {
  font-size: 2.5vw;
  font-family: Dancing Script;
  /* font-family: "Amatic SC", cursive; */
}
.cafe-location-container {
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: rgb(200, 179, 147);
  background: linear-gradient(
    355deg,
    rgb(230, 205, 169) 0%,
    rgb(223, 205, 164) 36%,
    rgb(235, 219, 183) 100%
  ); */
}
.cafe-location-title {
  font-size: 4vw;
  color: #000000;
  font-family: "Amatic SC", cursive;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, #9f7a5a 0%);
  background-position: 0% 1.4em;
}
.cafe-iframe {
  display: flex;
  justify-content: center;
  padding: 1vw;
}
.cafe-iframe-inside {
  border: 7px outset #9f7a5a;
}
.cafe-location-address {
  font-size: 3vw;
  color: #000000;
  font-family: "Amatic SC", cursive;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, #9f7a5a 0%);
  background-position: 0% 1.4em;
}
.address-p {
  text-align: center;
  font-size: 1.5vw;
  font-style: italic;
  padding-bottom: 5em;
}
.hand-container {
  display: flex;
  justify-content: center;
}
.hand-img {
  transition: 0.5s;
  padding-bottom: 15em;
  transform: scale(1.1);
  filter: brightness(0);
  padding-top: 5em;
}
.hand-img:hover {
  transform: scale(1.2);
  filter: brightness(1);
}
.hand-img:active {
  transition: 0.1s;
  filter: brightness(2);
  transform: scale(1.18);
}
.cafe-about-title.hebrew {
  text-align: right;
}
.cafe-about-head.hebrew {
  flex-direction: row-reverse;
}
.second-block-text.hebrew > p {
  text-align: right;
  align-self: flex-end;
}
@media (max-width: 990px) {
  .cafe-second-block {
    flex-direction: column-reverse;
  }
  .cafe-about-head {
    flex-direction: column;
    align-items: center;
  }
  .cafe-text-container {
    width: 100%;
    order: 0;
  }
  .cafe-about-title {
    font-size: 4vw;
    text-align: center;
    line-height: 1em;
  }
  .cafe-about-title mark {
    font-size: 10vw;
  }
  .cafe-about-title > b {
    font-size: 3.5vw;
  }
  .cafe-about-p {
    font-size: 3vw;
    width: fit-content;
  }
  .cafe-location-title {
    font-size: 13vw;
  }
  .cafe-location-address {
    font-size: 10vw;
  }
  .cafe-about-head img {
    width: 60%;
    /* width: 60%;
    position: fixed;
    bottom: calc(100% - 600px);
    z-index: -1;
    filter: opacity(0.3); */
  }
  .cafe-sign {
    width: 70%;
  }
  .second-block-text p {
    font-size: 3vw;
  }
  .second-block-text p b {
    font-size: 4vw;
  }
  .address-p {
    font-size: 4vw;
  }
}
