#homepage-container {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Antipasto Pro Medium";
}

.content {
  font-family: "Nunito";
  font-weight: 600;
}
