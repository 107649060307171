/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#analyze-banner-container {
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  /* background-image: linear-gradient(
      rgba(255, 255, 255, 0.03),
      rgba(255, 255, 255, 0.03)
    ),
    url("../../../media/img/HomePage/type-writer.jfif"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

#analyze-banner-container .title {
  text-align: center;
  font-size: 2.5vw;
  /* color: #ffaaa6; */
  color: black;

  font-family: "Antipasto Pro extralight";
}

#analyze-banner-container .sub-title {
  /* color: #ffdbda; */
  color: black;

  font-size: 1.5vw;
  margin-bottom: 2%;
}

#analyze-banner-container .form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2vw;
}
#analyze-banner-container .form-container.hebrew {
  flex-direction: row-reverse;
}
#analyze-banner-container .form-container > input {
  margin: 0px !important;
  text-align: center;
  font-size: 1.5vw;
  height: auto;
  width: 25%;
}

.submit-btn {
  font-size: 1.5vw;
  background-color: black;
  color: white;
  border: none;
  width: 10em;
  transition: 0.3s;
  border: 1px solid black;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}
.submit-btn:hover {
  background-color: white;
  color: black;
  border: 1px solid rgb(206, 206, 206);
}
@media (max-width: 990px) {
  #analyze-banner-container .form-container {
    flex-direction: column;
  }
  #analyze-banner-container .form-container.hebrew {
    flex-direction: column;
  }
  #analyze-banner-container .form-container > input {
    width: 100%;
    height: 3em;
    font-size: 3vw;
    letter-spacing: 1vw;
    align-self: center;
  }
  .submit-btn {
    letter-spacing: 1vw;
    width: 90vw;
    height: 3em;
    font-size: 3vw;
  }
  #analyze-banner-container .title {
    font-size: 7vw;
  }
  #analyze-banner-container .sub-title {
    font-size: 4vw;
  }
}
