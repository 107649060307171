/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */
.NormalCarousel .reaction-video-thumbnail img {
  padding: 0vw !important;
}
.slick-slider.slick-initialized {
  max-width: 1380px;
  margin: auto;
}
.NormalCarousel {
  width: 100%;
  margin: 10rem auto;
  /* height: 570px; */
  margin-bottom: 0em;
}
.slide {
  padding-bottom: 5em;
}
/* .activeSlide::after {
  content: url("../../media/img/play-button.svg");
  position: relative;
  display: block;
  height: 8vw;
  width: 8vw;
  bottom: 18vw;
  margin-bottom: -18vw;
  padding-bottom: -18vw;
  left: calc(100% - 19.9vw);
  filter: invert(1) opacity(0.7);
} */
.play-button-homepage {
  font-size: 13em;
  left: calc(50% - 0.5em) !important;
  top: calc(50% - 0.9em) !important;
  /* left: 25%;
  
  top: 15% !important; */
  /* font-size: 13em;
  top: 4vw !important;
  left: 6vw !important; */
  /* font-size: 13vw !important; */
}
/* @media (min-width: 1550px) {
  .play-button-homepage {
    transform: translateX(13vw) translateY(13vw) scale(10) !important;
  }
}
@media (min-width: 2360px) {
  .play-button-homepage {

    top: 0 !important;
    font-size: 13vw !important;
    transform: translateX(10vw) translateY(10vw) scale(10) !important;
  }
} */
.activeSlide .video-player {
  /* width: 20rem; */

  margin: 0 auto;
}
.inactiveSlide {
  pointer-events: none;
  transform: scale(0.6);
  transition: transform 300ms;
  opacity: 0.5;
}

.inactiveSlide > i {
  display: none !important;
}
/* .slide {
} */
.slide .video-player > div > iframe {
  height: 344px;
  width: 454px;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.carousel-breadcrumbs {
  /* width: 10em; */
  /* text-align: center; */
  /* position: relative;
  left: calc(50% - 5em); */
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
.carousel-breadcrumbs img {
  width: 15px;
  padding-left: 2px;
  padding-right: 2px;
}
.iframe-blob > div > iframe {
  -webkit-box-shadow: 0px 3px 0px 2px rgba(0, 0, 0, 0.1),
    0px 6px 0px 4px rgba(0, 0, 0, 0.1), 0px 9px 0px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 0px 2px rgba(0, 0, 0, 0.1),
    0px 6px 0px 4px rgba(0, 0, 0, 0.1), 0px 9px 0px 6px rgba(0, 0, 0, 0.1);
}
/* HARD CODED BLOBS */
/* .vid0 > div > iframe {
  border-radius: 38% 62% 46% 54% / 44% 42% 58% 56%;
}
.vid1 > div > iframe {
  border-radius: 40% 60% 52% 48% / 40% 37% 63% 60%;
}
.vid2 > div > iframe {
  border-radius: 46% 54% 57% 43% / 42% 54% 46% 58%;
}
.vid3 > div > iframe {
  border-radius: 29% 71% 46% 54% / 55% 45% 55% 45%;
}
.vid4 > div > iframe {
  border-radius: 45% 55% 69% 31% / 40% 67% 33% 60%;
}
.vid5 > div > iframe {
  border-radius: 46% 54% 57% 43% / 42% 54% 46% 58%;
} */
.video-js .vjs-big-play-button {
  border-radius: 100% !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 3em !important;
}
.play-button:hover > .reaction-video-thumbnail img {
  padding: 5em;
}
@media (max-width: 990px) {
  .binder-active {
    transform: scale(1.5);
  }
  .activeSlide .video-player {
    transform: scale(1.4);
    margin-left: 5vw !important;
    margin-right: 5vw !important;
  }
  .inactiveSlide {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .NormalCarousel {
    margin: 0em;
  }
  .play-button-homepage {
    font-size: 7em !important;
    left: calc(50% - 0.5em) !important;
    top: calc(50% - 1em) !important;
  }
}
