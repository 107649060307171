#hp-institute-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3%;

  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 100px; */
}

#hp-institute-container .title {
  font-size: 4vw;
  position: relative;
  top: 1em;
  margin-top: -1em;
}

#hp-institute-container .carousel-container {
  background-color: rgb(27, 55, 216);
  height: 50vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: wheat;
}
@media (max-width: 990px) {
  #hp-institute-container .title {
    font-size: 8vw;
  }
  #hp-institute-container {
    padding-bottom: 0%;
  }
}
