#how-it-all-started-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 3%;

  background-color: #f5f5f5;
}

#how-it-all-started-container .title {
  font-size: 3vw;
  font-weight: 400;
}
#how-it-all-started-container .title.hebrew {
  font-size: 4vw;
  font-weight: 400;
}

#how-it-all-started-container .content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  gap: 3vw;
  text-align: justify;
}
#how-it-all-started-container .content-container.hebrew {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  gap: 3vw;
}
.youtube-iframe-about {
  height: 30vw;
  width: 60%;
}

#how-it-all-started-container .text-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
}

#how-it-all-started-container .text-btn-container > p {
  display: inline-flex;
  font-size: 1.7vw;
}
#how-it-all-started-container .text-btn-container > p.hebrew {
  display: inline-flex;
  font-size: 2.25vw;
  text-align: right;
}

#how-it-all-started-container .btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#how-it-all-started-container .btn-container > button {
  font-size: 1vw;
  padding-left: 5%;
  padding-right: 5%;

  border-width: 1px;
  font-family: "Nunito";
  font-weight: 900;
}
.fullstory-button {
  background-color: black;
  color: white;
  padding: 10px;
  width: 300%;
  transition: 0.3s;
  letter-spacing: 5px;
  text-transform: capitalize;
}
.fullstory-button:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
@media (max-width: 990px) {
  #how-it-all-started-container .title {
    font-size: 8vw;
  }
  #how-it-all-started-container .title.hebrew {
    font-size: 8.5vw;
    font-weight: 400;
  }
  #how-it-all-started-container .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 0em !important;
  }
  #how-it-all-started-container .text-btn-container {
    width: 80%;
  }
  #how-it-all-started-container .text-btn-container > p {
    font-size: 5vw;
  }
  #how-it-all-started-container .text-btn-container > p.hebrew {
    font-size: 4vw;
  }
  #how-it-all-started-container .btn-container > button {
    font-size: 3.5vw;
  }
  #how-it-all-started-container .content-container.hebrew {
    flex-direction: column;
  }
}
