#contact-footer-container {
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
  z-index: 5 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: white; */
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.93),
      rgba(255, 255, 255, 0.83)
    ),
    url("../../media/img/Cafe/page-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
#contact-footer-container .title {
  text-align: center;
  font-size: 3vw;
  text-transform: uppercase;
}

#contact-footer-container .form-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
#contact-footer-container .form-container.hebrew {
  flex-direction: row-reverse;
}
@media (max-width: 990px) {
  #contact-footer-container .form-container {
    flex-direction: column !important;
    gap: 2em;
    align-items: center;
  }
  #contact-footer-container .form-container .submit-div .submit-btn {
    width: 80vw;
    height: 2em;
    font-size: 5vw;
  }
  #contact-footer-container .title {
    font-size: 7vw;
  }
}
