#hp-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #ffffff;
  padding-top: 3%;
  /* padding-bottom: 5%; */
}

#hp-projects-container .title {
  font-size: 3vw;
  font-weight: 400;
}

#hp-projects-container .projects-imgs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2vw;

  margin-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
}

#hp-projects-container .img-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  position: relative;
  bottom: 0em;
  transition: 0.3s;
}
#hp-projects-container .img-text-container:hover {
  /* bottom: 1em; */
  transform: scale(1.1);
  filter: brightness(1.2);
}
#hp-projects-container .img-text-container > h4 {
  font-size: 1.5vw;
}

#hp-projects-container img {
  margin-bottom: 3%;
}

@media (max-width: 990px) {
  #hp-projects-container .projects-imgs-container {
    flex-direction: column;
    align-items: center;
  }
  #hp-projects-container .img-text-container {
    width: 70%;
  }
  #hp-projects-container .img-text-container > h4 {
    font-size: 6vw;
    padding-bottom: 2em;
    text-align: center;
  }

  #hp-projects-container .title {
    font-size: 8vw;
    font-weight: 400;
  }
}
