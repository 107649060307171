#private-sector-container h3 {
  font-size: 4.5vw;
  font-family: Nunito;
  text-align: center;
  padding-top: 4.5vw;
  padding-bottom: 4.5vw;
}
#private-sector-container h4 {
  font-size: 3vw;
  font-family: Nunito;
  text-align: center;
  padding: 1em;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
}
#private-sector-container .first-container {
  display: flex;
  width: 90%;
  justify-content: center;
}
.first-container.hebrew {
  flex-direction: row-reverse;
  text-align: right;
}

.left-container {
  flex-direction: row-reverse;
}

.left-container.hebrew {
  flex-direction: row !important;
}
#private-sector-container .first-container p {
  font-size: 1.3vw;
  width: 50%;
  font-family: Nunito;
  padding: 5vw;
  padding-right: 1vw;
}
.first-container.hebrew p {
  font-size: 1.6vw;
  font-family: Rubik;
}
#private-sector-container .first-container img {
  width: 50%;
  height: 100%;
  padding: 5vw;
  padding-right: 0vw;
}
@media (max-width: 990px) {
  #private-sector-container .first-container {
    flex-direction: column-reverse !important;
    align-items: center;
    width: 100%;
  }
  #private-sector-container .left-container {
    align-items: center;
    flex-direction: column-reverse !important;
  }
  #private-sector-container h3 {
    font-size: 6vw;
  }
  #private-sector-container h4 {
    font-size: 4vw;
    padding: 1em;
  }
  #private-sector-container .first-container p {
    font-size: 3.1vw;
    width: 100%;
    text-align: justify;
  }
  .first-container.hebrew p {
    font-size: 3.1vw;
    width: 100%;
    text-align: center;
  }
  #private-sector-container .first-container img {
    width: 75%;
    padding: 0;
  }
}
