@import url("https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap");
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.header {
  position: sticky;
  z-index: 1000;
  top: 0;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.29);
}
a:not(fab) {
  text-decoration: none;
}

.header-top {
  min-width: 100%;
  background-color: black;
  padding: 1px;
  color: white;
  /* line-height: 40px; */
}

.header-bot {
  align-items: flex-start;
  /* width: 100%; */
  background-color: white;
  height: 7.5em;
  display: flex;
  justify-content: space-between;
}
.header-bot > img {
  width: 230px !important;
  height: fit-content;
}
.header-bot-he {
  align-items: flex-start;

  width: 100%;
  background-color: white;
  height: 7.5em;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.phone {
  /* font-family: "Staatliches", cursive; */
  font-family: "Amatic SC", cursive;
  width: max-content;
  font-size: 14px;
  /* line-height: 100%; */
  letter-spacing: 0.2em;
  margin: 0.1em;
  margin-left: 2em;
  padding-right: 0.5em;
}
.socials {
  display: flex;

  margin: 0px;
  padding: 0px;
  padding-left: 1em;
  border-left: 1px solid #fff;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgb(255, 255, 255);
}

.socials > a {
  color: white;
  font-size: 14px;
  margin-left: 1em;
}

.wrapper {
  margin: 1em;
  display: flex;
  max-height: 2em;
}
.logo {
  max-width: fit-content;
  max-height: 70%;
  margin-top: 1em;
  /* margin-left: 2em; */
  cursor: pointer;
}

.dropdown-head {
  font-family: "Amatic SC", cursive;
  font-weight: 900;
  font-size: 20px;
  line-height: 4em;
  letter-spacing: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  transition: 0.2s;
  width: auto;
  min-width: max-content;
}
.dropdown-head:hover {
  background-color: #000000;
  color: white;
}

.header-nav {
  height: fit-content;
  display: flex;
  margin: 0px;
  margin-top: 1.5em;
  padding: 0px;
  padding-left: 1em;
  border-left: 1px solid rgb(0, 0, 0);
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgb(0, 0, 0);

  border-right: 1px solid rgb(0, 0, 0);
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgb(0, 0, 0);
}
.header-nav > a {
  color: black;
  font-family: "Amatic SC", cursive;

  font-size: 20px;
  letter-spacing: 0.2em;
  padding-left: 1em;
  padding-right: 1em;
  transition: 0.2s;
  position: relative;
  bottom: 0em;
}
.header-nav > a:hover {
  color: rgb(150, 150, 150);
  text-decoration: none;
  position: relative;
  bottom: 0.3em;
}
.dropdowns {
  display: flex;
}
.dropdowns-he {
  display: flex;
  flex-direction: row-reverse;
}
.options-wrapper {
  margin-top: -0.25em;
  display: none;
  background-color: #000000;
  /* box-shadow: 0px 0px 40px 20px #0ff; */
  /* in order: x offset, y offset, blur size, spread size, color */
  /* blur size and spread size are optional (they default to 0) */
}

.dropdown-head:hover > .options-wrapper {
  display: block;
  background-color: #000000;
}

.dropdown-option {
  box-sizing: border-box;
  margin-left: -1em;
  padding-left: 1em;
  padding-right: 1em;
  width: 15em;
  position: absolute;
  background-color: black;
  transition: 0.1s;
  min-width: max-content;
}

.dropdown-option:hover {
  color: rgb(250, 50, 80);
}
.do2 {
  margin-top: 80px;
}
.do3 {
  margin-top: calc(80px * 2);
}
.do4 {
  margin-top: calc(80px * 3);
}
.do5 {
  margin-top: calc(80px * 4);
}
.flag-icon {
  width: 1.3em;
  height: 1em;
  position: relative;
  top: 1.4em;
}
.social {
  transition: 0.25s;
  position: relative;
  bottom: 0em;
  padding: 0.4em;
}
.social:hover {
  color: rgb(225, 225, 225);
  position: relative;
  bottom: 0.1em;
}
.header-action-container {
  float: right;
}
.header-action {
  width: max-content;
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px;
  font-size: large;
  position: relative;
  bottom: -0.8em;
  margin-right: 1em;
  margin-left: 1em;
  font-family: "Amatic SC", cursive;
  letter-spacing: 2px;
  transition: 0.5s;
  border: 1px solid rgba(0, 0, 0, 0);
}
.header-action:hover {
  background-color: white;
  color: #000000;
  border: 1px solid black;
  /* border-radius: 10px; */
  -webkit-box-shadow: 0px 0px 0px 2px #000000, 0px 0px 0px 4px #4b4c4b;
  box-shadow: 0px 0px 0px 2px #000000, 0px 0px 0px 4px #4b4c4b;
}

/* HAMBURGER MENU */
/* Position and sizing of burger button */

@media (max-width: 990px) {
  .header-top {
    display: none;
  }
  .header-bot-he {
    justify-content: space-between;
  }
  .header-bot {
    justify-content: space-between;
  }
  .header-action-container {
    display: none;
  }
  .logo {
    max-width: fit-content;
  }
  .dropdowns {
    display: none;
  }
  .dropdowns-he {
    display: none;
  }
}
