.burger {
  transition: 1s;
  display: none;
  top: 1em;
  position: relative;
  width: max-content;
}
.burger-hr {
  /* width: 120%;
  left: -3vw;
  position: relative; */
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8c8c inset;
}
.hamburger-footer {
  padding-top: 2vw;

  background-color: #101010;
  /* min-height: 30vw; */
  margin-top: 1vw;
  color: white;
  text-align: justify;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-bottom: 8vw;
  min-height: 20vh;
}
.footer-wrapper-inside {
  display: flex;
  font-size: 4.5vw;
}
.hamburger-footer i {
  margin-right: 1.5vw;
  transition: 0.1s;
}

.hamburger-footer i:active {
  transform: scale(1.05);
}
.hamburger-footer p {
  /* padding: 1em; */

  font-family: Nunito;
}
.footer-wrapper {
  width: fit-content;
  height: fit-content;
  transform: translateY(-1vh);
}
.burger-collapses {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.burger-menu {
  /* direction: rtl; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  position: relative;
  top: 60px;
  width: 100%;
  height: 100vh;
  background: rgb(226, 226, 225);
  background: linear-gradient(
    0deg,
    rgb(212, 212, 212) 0%,
    rgba(237, 236, 233, 1) 36%,
    rgba(247, 247, 246, 1) 100%
  );
}
.burger-bottom-divider {
  background-color: #101010;
  height: 30vh;
  overflow: hidden;
}
.burger-buttons {
  background: rgb(250, 250, 250);
  padding: 3vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
  display: flex;
  justify-content: space-between;
}
.burger-buttons button {
  border: none;
  color: white;
  background-color: black;
  font-size: 4vw;
  padding: 1vw;
  margin: 1vw;
  width: 40vw;
  transition: 0.3s;
}
.burger-buttons button:hover {
  color: black;
  background-color: rgb(224, 224, 224);
}
.burger-buttons button:active {
  transition: 50ms;
  color: black;
  transform: scale(1.05);
  background-color: rgb(224, 224, 224);
}
.burger-flags {
  /* margin-bottom: 1vw; */
  margin-top: 1vw;
  display: flex;
  justify-content: space-evenly;
  background: rgb(250, 250, 250);
  padding: 3vw;
}
.first {
  margin-top: 3vh;
}
.burger-flags img {
  -webkit-box-shadow: 0px 1px 1px 1px #a0a0a080;
  box-shadow: 0px 1px 1px 1px #a0a0a080;
  width: 10vw;
  height: 8vw;
  object-fit: cover;
  transition: 0.1s;
}
.burger-flags img:active {
  -webkit-box-shadow: 0px 0px 1px 1px #00000080;
  box-shadow: 0px 1px 1px 1px #00000080;
  transform: scale(1.05);
  filter: brightness(1.5);
}
.burger-menu-content {
  /* display: block;
  position: relative;
  width: max-content; */
  padding: 3vw;
  background-color: rgb(250, 250, 250);
  /* margin-bottom: 1vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.burgerTitles-inactive::after {
  content: "+";
  float: right;
  font-size: 7vw;
  margin-right: 1vw;
}
.burger-socials {
  background-color: rgb(250, 250, 250);
  display: flex;
  justify-content: center;
  font-size: 7vw;
}
.burger-socials > a {
  color: #101010 !important;
}
.burgerTitles-active::after {
  content: "-";
  float: right;
  font-size: 7vw;
  margin-right: 1vw;
}
.burgerTitles-active.hebrew::after {
  content: "";
  float: right;
  font-size: 7vw;
  margin-right: 1vw;
}
.burgerTitles.hebrew {
  text-align: right;
}
.burgerTitles-inactive.hebrew::after {
  content: "";
  float: right;
  font-size: 7vw;
  margin-right: 1vw;
}
.burgerTitles-active.hebrew::before {
  content: "-";
  float: left;
  font-size: 7vw;
  margin-left: 1vw;
}
.burgerTitles-inactive.hebrew::before {
  content: "+";
  float: left;
  font-size: 7vw;
  margin-left: 1vw;
}
.burger-subTitles.hebrew {
  text-align: right;
}
.burgerTitles {
  /* background-color: red; */
  font-size: 7vw !important;
  font-family: Nunito;
  /* text-align: center; */
  /* position: relative; */
  /* transition: 0.3s; */
}
.burger-logo {
  margin: 1vw;
  width: 30%;
  margin-left: 8vw;
  filter: brightness(0.2);
  transition: 0.3s;
}
.burger-logo:hover {
  filter: brightness(0.6);
}
.burger-logo:active {
  transition: 0.1s;

  filter: brightness(0.9);
}
.burger-subTitles {
  font-size: 4vw !important;
  /* padding: 0.5em; */
  font-family: Nunito;
  position: relative;
  left: 2vw;
}
.burger-menu-content a {
  color: rgb(20, 20, 20) !important;
}
.burger-menu-content a:link {
  color: rgb(20, 20, 20) !important;
}
.burger-menu-content a:visited {
  color: rgb(20, 20, 20) !important;
}
.burger-menu-content a:active {
  color: rgb(124, 124, 124) !important;
  background-color: rgb(255, 255, 255, 0.1);
}
.burger-menu-content ul li {
  padding: 1vw;
  list-style-type: none;
  font-size: 2vw;
  font-family: Nunito;
  list-style: none;
}
/* .accordion {
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  background-color: rgb(235, 235, 235, 0.7);
  max-height: 0vh;
  overflow: hidden;
} */
/* .accordion-hide {
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
.accordion.accordion-show {
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: fit-content;
} */
@media (max-width: 990px) {
  .burger {
    /* background-color: rgb(255, 255, 255); */

    float: right;
    display: block;
  }
}
