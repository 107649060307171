#homepage-hero-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
  background-repeat: repeat;
  animation-name: bgMove !important;
  animation-duration: 60s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  width: 100%;
}

@keyframes bgMove {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
/* #homepage-hero-container:hover {
  background-position: 100%;
} */
#homepage-hero-container .title-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Nunito;
  font-weight: 600;
  /* bottom: 4vw; */
  position: relative;
}

#homepage-hero-container h1 {
  font-size: 5vw;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0.5em;
}

#homepage-hero-container .title-logo-container img {
  width: 60%;
  height: auto;
}

.shadow-container {
  display: flex;
  box-shadow: 10px 10px black;
  width: 100%;
}

.lead-button {
  border: none;
  padding: 0.3em;
  padding-right: 1.5em;
  padding-left: 1.5em;
  font-size: 3vw;
  width: fit-content;
  position: relative;
  bottom: -0.5em;
}
.transition-lead {
  background-color: white;
  color: black;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: 0.3s;
  opacity: 0.8;
}

.transition-lead:hover {
  background-color: black;
  border: 2px solid rgb(0, 0, 0);
  opacity: 1;
  color: white;
  transform: scale(1.1);
}
.transition-lead:active {
  opacity: 0.9;
  transition: 10ms;
  color: rgb(200, 200, 200);
  transform: scale(1.05);
}
.hero-img-container {
  width: 33%;
  position: relative;
  bottom: -2em;
}
@media (max-width: 990px) {
  .title-logo-container img {
    display: none;
  }
  .lead-button {
    display: none;
  }
  .hero-img-container {
    width: 70vw;
    left: 10vw;
  }
  #homepage-hero-container {
    /* height: 80vh; */
    background-size: cover;
    flex-direction: column;
  }
  #homepage-hero-container h1 {
    font-size: 9vw;
    color: rgb(0, 0, 0);
    padding: 0.5em;
    top: 5vw;
    /* left: 5vw; */
    position: relative;
  }
  #homepage-hero-container h1 > span {
    font-size: 8.5vw;
  }
}
