#who-is-eilon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  background-color: #ffffff;
  /* padding-left: 5%;
  padding-right: 5%; */
}
.about-p-img-container {
  display: flex;
}
.about-p-img-container img {
  object-fit: contain;
  /* object-fit: contain;
  object-position: -2vw; */
  border-radius: 71% 29% 41% 59% / 36% 59% 41% 64%;
  /* -webkit-box-shadow: -10px 14px 0px 3px #363a4944;
  box-shadow: -10px 14px 0px 3px #363a4944; */
  /* -webkit-box-shadow: 8px 0px 0px 0px #dcd0c0, 0px 8px 0px 0px #adaaa920,
    -8px 0px 0px 0px #4e4e5620, 0px 0px 0px 8px #866d6d40,
    -18px -4px 42px 3px rgba(54, 58, 73, 0);
  box-shadow: 8px 0px 0px 0px #dcd0c0, 0px 8px 0px 0px #adaaa920,
    -8px 0px 0px 0px #4e4e5620, 0px 0px 0px 8px #866d6d40,
    -18px -4px 42px 3px rgba(54, 58, 73, 0); */
  width: 27%;
  margin-left: 7vw;
  left: 5vw;
  position: relative;
  max-height: fit-content !important;
}
#who-is-eilon-container .title {
  font-weight: 400;
  font-size: 4vw;
  margin-bottom: 4vh !important;
}
#who-is-eilon-container .pre-title {
  font-weight: 400;
  font-size: 2.5vw;
  margin-bottom: 0vh !important;
}
#who-is-eilon-container .content {
  font-size: 1.66vw;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;

  padding-bottom: 2%;
}

#who-is-eilon-container .sub-title {
  font-size: 2.5vw;
  font-weight: 400;
  margin-top: 5vw;
  text-align: center;
  padding-bottom: 1%;

  font-family: "Antipasto Pro Medium";
}

#who-is-eilon-container .carousel-container {
  background-color: rgb(27, 55, 216);
  height: 50vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: wheat;
}
@media (max-width: 990px) {
  .about-p-img-container img {
    width: 75%;
    left: 0vw;
  }
  .about-p-img-container {
    align-items: center;
    flex-direction: column;
  }
  #who-is-eilon-container .content {
    font-size: 4vw;
    text-align: center;
    padding: 0%;
    padding-top: 1em;

    /* text-align: justify; */
  }
  #who-is-eilon-container .title {
    font-size: 8vw;
  }
  #who-is-eilon-container .sub-title {
    font-size: 5vw;
  }
  #who-is-eilon-container .pre-title {
    font-size: 5vw;
    margin-bottom: 2vh !important;
  }
}
