.contact-us-container {
  display: flex;
  flex-direction: column;
}

.contact-us-title {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 45px;
}
