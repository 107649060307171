.footer-container {
  background-color: #101010;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 75vw;
  align-self: center;
  align-items: flex-start;
  padding-bottom: 1vw;
  padding-top: 2vw;
}
.footer.hebrew {
  flex-direction: row-reverse;
}
.footer-logo-container {
  text-align: center;
}

.english-logo {
  width: 10vw;
  height: auto;
  filter: hue-rotate(170deg) saturate(3);
}
.hebrew-logo {
  width: 10vw;
  height: auto;
  filter: saturate(3);
}
.footer-logo-description {
  color: white;
  font-size: 1vw;
}

.footer-nav-header {
  color: white;
}
.footer-nav-ul {
  list-style-type: none;
  text-align: justify;
  font-size: 1vw;
}
.footer-nav-ul.hebrew {
  text-align: right;
}
.footer-nav-ul > li {
  /* color: rgb(158, 154, 154); */
  color: #ffffff;
  margin-bottom: 0vw !important;
}
.footer-nav-ul > li:not(:first-child) {
  padding-top: 1vw;
}
.footer-nav-ul > li > a {
  color: white;
}
a:link {
  color: white;
}
a:visited {
  color: white;
}
.footer-input-container {
  display: flex;
  flex-direction: row;
}

.footer-social-container {
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: fit-content;
}

.contact-form-input-footer {
  color: rgb(20, 20, 20);
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.5em;

  font-size: 18px;
  border-width: 1px;
  border-radius: 30px;

  margin-bottom: 4em;
}

.footer-register-title {
  margin-bottom: 1em;
}

.footer-social {
  display: flex;
  flex-direction: column;
}
.footer-social .social {
  font-size: 1.5vw !important;
}
.footer-social-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  filter: hue-rotate(170deg) saturate(3);
  padding-top: 1vw;
}

.footer-social-icons-container > a {
  font-size: 1.5vw;
  color: #1b8aae;
}

.footer-sumbit-button {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 100%;
  border: 1px 0px 0px 0px;
  box-shadow: none;
}

.sub-footer {
  background-color: black;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
  border-top: 1px solid rgb(88, 88, 88);
}

.sub-footer-text {
  font-size: 12px;
}

.footer-contact-info-container {
  display: flex;
  flex-direction: column;
}

.footer-contact-icon-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-contact-icon-text p {
  width: max-content;
}
.footer-nav-diagnosis-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .footer-nav-ul > li:not(:first-child) {
    padding-top: 0.3vw !important;
  }
}
@media (max-width: 900px) {
  .footer {
    justify-content: center;
  }
  .footer-nav-diagnosis-container {
    display: none;
  }
  .footer-nav-lectures-container {
    display: none;
  }
  .footer-nav-courses-container {
    display: none;
  }

  .footer-nav-contact-logos-container {
    display: none !important;
  }
  .footer-social .social {
    font-size: 3vw !important;
  }
  .footer-nav-ul > li {
    padding-top: 0vw !important;
  }
  .english-logo {
    width: 30vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
  .hebrew-logo {
    width: 30vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
  .footer-social-icons-container > a {
    font-size: 8vw;
    padding-bottom: 1vw;
  }
}

@media (max-width: 500px) {
  .footer-social .social {
    font-size: 4vw !important;
  }
}
@media (max-width: 320px) {
  .footer-social .social {
    font-size: 5vw !important;
  }
}

.footer-nav-contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-nav-contact-logos-container {
  display: flex;
  flex-direction: column;
  filter: brightness(1.5) !important;
  filter: sepia(100%) !important  ;
  padding-top: 0.5vw;
}
.footer-nav-contact-text-container {
  display: flex;
  flex-direction: column;
}
.footer-contact-icon {
  filter: invert() saturate(500);
  /* filter: brightness(2) !important; */
  height: 2vw;
  width: 2vw;
}

.footer-nav-logo-contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1vw;
}
.footer-nav-logo-contact-container.hebrew {
  flex-direction: row-reverse;
}
.footer-nav-logo-contact-container.hebrew > p {
  text-align: right;
}
.footer-nav-logo-contact-container > p {
  font-size: 0.75vw;
  margin-bottom: 0px;
  padding-left: 1vw;
  padding-right: 1vw;
}
