#lectures-and-shows-description-container {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 4vh;

  background-color: #ffffff;
}

#lectures-and-shows-description-container .content {
  padding-top: 3%;
  padding-right: 5%;
  padding-left: 5%;
  text-align: center;
  font-size: 1.7vw;
  font-weight: 100;
  font-family: Nunito;
}
#lectures-and-shows-description-container .content.hebrew {
  font-size: 1.8vw;
  font-family: Rubik !important;
}
#lectures-and-shows-description-container .event-includes-container {
  padding-top: 8%;
  padding-right: 10%;
  padding-left: 10%;
}

#lectures-and-shows-description-container .lectures-ul > li {
  font-size: 1.5vw;
  font-family: "Antipasto Pro Light";
  line-height: 2.25vw;
  padding-left: 2%;
  /* font-weight: 500; */
}
#lectures-and-shows-description-container .lectures-ul.hebrew > li {
  font-family: Rubik;
  text-align: right;

  list-style: none;
  /* font-weight: 500; */
}
#lectures-and-shows-description-container .lectures-ul.hebrew > li::after {
  content: "-";
}
#lectures-and-shows-description-container .lectures-ul > li:not(:last-child) {
  margin-bottom: 1%;
}

#lectures-and-shows-description-container .sub-header {
  font-family: "Antipasto Pro Bold";
  text-decoration: none;
  font-size: 1.66vw;
}
#lectures-and-shows-description-container .sub-header.hebrew {
  text-align: right;
}
@media (max-width: 990px) {
  #lectures-and-shows-description-container .content {
    padding-top: 3%;
    padding-right: 5%;
    padding-left: 5%;
    text-align: center;
    font-size: 4vw;
    font-weight: 100;
    font-family: Nunito;
  }
  #lectures-and-shows-description-container .content.hebrew {
    padding-top: 3%;
    padding-right: 5%;
    padding-left: 5%;
    text-align: center;
    font-size: 4vw;
    font-weight: 100;
    font-family: Rubik;
  }
  #lectures-and-shows-description-container .sub-header {
    font-size: 4vw;
  }

  #lectures-and-shows-description-container .lectures-ul > li {
    font-size: 4vw;
    font-family: "Antipasto Pro Light";
    line-height: 4vw;
    padding-left: 2%;

    /* font-weight: 500; */
  }

  #lectures-and-shows-description-container .lectures-ul.hebrew > li {
    font-size: 4vw;

    padding-right: 2%;

    /* font-weight: 500; */
  }
}
