.InstituteCarousel {
  width: 80vw;
  height: auto;
  margin-top: 5vh;
  padding-bottom: 2vw;
}

.InstituteCarousel .slick-slider {
  max-width: 70vw;
}

.InstituteCarousel .slick-track {
  height: 35vw;
}
.inactive-Slide {
  pointer-events: none;
}
.InstituteCarousel .card {
  height: fit-content !important;
}
.slidee {
  padding-bottom: 5em;
  padding-top: 5em;
  transform: scale(0.6);
  transition: transform 300ms;
  opacity: 0.5;
  height: 30vw;
}
.active-Slide:hover {
  transform: scale(1.11);
}
.active-Slide:active {
  transform: scale(1);
}
.active-Slide {
  transform: scale(1);
  opacity: 1;
  transition: 0.5s;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
.card-carousel-title {
  font-size: 2vw;
  text-align: center;
}
.carousel-card {
  width: 100% !important;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.card-carousel-img {
  width: 27vw;
  height: auto !important;
}

@media (max-width: 990px) {
  .slidee {
    transform: scale(0.7);
  }
  .active-Slide {
    transform: scale(1.1);
  }

  .InstituteCarousel {
    margin-top: 2vw !important;
    width: 100vw;
    height: auto;
    padding-top: 2vw;
    font-size: 1vw;
  }
  .card-carousel-title {
    font-size: 4vw;
    text-align: center;
  }
  .InstituteCarousel .slick-slider {
    max-width: 100vw !important;
    padding-bottom: 5vw;
  }
  .card-carousel-img {
    width: 33vw;
  }
  .InstituteCarousel .slick-track {
    height: 60vw !important;
  }
}
