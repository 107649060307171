input {
  border-radius: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0.4vw !important;
}

textarea {
  border-radius: 0px !important;
}
.form-book-wrapper {
  /* transform: translateY(-5vw); */
}
#lectures-book-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding-top: 6vw;
}

#lectures-book-container .sub-header {
  font-family: "Antipasto Pro Bold";
  text-decoration: none;
  font-size: 3vw;
}

#lectures-book-container .form-container {
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 50vw;
  height: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vw;
  padding-top: 2.5vw;
}

#lectures-book-container .form-container-2 {
  background-image: url("../../../media/img/LecturesAndShowsPage/notebook-bg-2.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 50vw;
  height: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vw;
  padding-top: 2.5vw;
}

#lectures-book-container .form-container-3 {
  background-image: url("../../../media/img/LecturesAndShowsPage/notebook-bg-3.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 50vw;
  height: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vw;
  padding-top: 2.5vw;
}

#lectures-book-container .top-btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 7.5vw;
  gap: 2vw;
  font-size: 1vw;
  justify-content: center;
  /* transform: translateX(12.9vw); */
}

#lectures-book-container .top-btn-container > button {
  border: 1px solid rgba(0, 0, 0, 0);
  padding-left: 2vw;
  padding-right: 2vw;
  font-size: 1.1vw;
  color: #ffffff;
  background-color: black;
  transition: 0.3s;
}
#lectures-book-container .top-btn-container > button:hover {
  background-color: rgb(136, 136, 136);
}
#lectures-book-container .top-btn-container > button:active {
  color: rgb(0, 0, 0);
  border: 1px solid rgba(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
#lectures-book-container .top-btn-container > button > a {
  color: inherit;
}
#lectures-book-container .event-form {
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0.5vw;
  margin-left: 25%;
  margin-right: 15%;
  transform: translateY(-1vw);
}
.event-form.hebrew {
  align-items: right;
  text-align: right;
}
#lectures-book-container .form-container-2 .event-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 25%;
  margin-right: 15%;
  padding: 0.5vw;
  /* gap: 2vw; */
}

#lectures-book-container .form-header {
  font-size: 2.7vw;
  padding-bottom: 0.6vw !important;
  margin-bottom: 0px !important;
  transform: translateY(-3vw);
}

#lectures-book-container .radio-container > input {
  /* width: 1vw; */
  height: auto;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

#lectures-book-container .radio-container {
  padding-bottom: 1vw;
  display: flex;
}
#lectures-book-container .radio-container.hebrew {
  flex-direction: row-reverse;
}
#lectures-book-container .radio-container > input:not(:first-child) {
  margin-left: 8%;
}

#lectures-book-container .radio-container > label {
  margin-left: 2%;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  font-family: "Antipasto Pro Regular";
  font-size: 1.2vw;
}
.course-hebrew {
  transform: translateX(1.9vw);
}
.seminar-radio {
  transform: translateX(1.9vw) scale(1.5);
}
.seminar-hebrew {
  transform: translateX(3.5vw);
}
#lectures-book-container .radio-container.hebrew > label {
  /* margin-left: 0% !important; */
  margin-right: 2% !important;
  text-align: right;
  font-family: Rubik;
}
#lectures-book-container .event-time-input-container > input.hebrew {
  text-align: right;
}
#lectures-book-container .event-time-input-container {
  display: flex;
  flex-direction: column;
  /* gap: 2vw; */
  width: 100%;
}
#lectures-book-container .input-fs.hebrew {
  text-align: right;
}
#lectures-book-container .lecture-textarea-style.hebrew {
  text-align: right;
}
#lectures-book-container .event-time-input-container.hebrew {
  /* flex-direction: row-reverse; */
}
#lectures-book-container .event-time-input-container.hebrew > input {
  text-align: right;
}

#lectures-book-container .lecture-input-style {
  /* width: 15vw; */
  width: 100%;
  font-size: 1.7vw;
  height: fit-content;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  line-height: normal !important;
}
#lectures-book-container input[type="date"] {
  /* width: 15vw; */
  width: 100%;
  font-size: 1.7vw;
  height: fit-content;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  line-height: normal !important;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
#lectures-book-container input[type="time"] {
  /* width: 15vw; */
  width: 100%;
  font-size: 1.7vw;
  height: fit-content;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  line-height: normal !important;
  -webkit-appearance: auto;
  appearance: none;
  -moz-appearance: auto;
}
.form-images-loader {
  position: absolute;
  z-index: -1000;
  opacity: 0;
}
.form-images-loader > img {
  position: absolute;
}
.seminar-radio-input {
  transform: translateX(1.5vw) scale(1.5) !important;
}
.form-select {
  font-size: 1.7vw !important;
  height: max-content;
  margin-bottom: 0.5em;
}
.form-select.hebrew {
  text-align: right;
}
.utility-form {
  position: absolute;
  z-index: -1000;
  cursor: none;
  opacity: 0;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
}
.checkbox-container.hebrew {
  flex-direction: row-reverse;
}
.checkbox-container > input[type="checkbox"] {
  max-width: 1.5vw;
  max-height: 1.5vw;
  margin: 1vw;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.checkbox-container > label {
  font-size: 1vw;
  pointer-events: none !important;
}
.checkbox-container.hebrew > label {
  font-size: 1.5vw;
}
.checkbox-title {
  font-size: 2vw;
}
.event-form > p {
  font-size: 1vw;
}
@media screen and (max-width: 990px) {
  .checkbox-title {
    font-size: 3vw;
  }
  .event-form > p {
    font-size: 2vw;
  }
  .checkbox-container > input[type="checkbox"] {
    max-width: 2vw;
    max-height: 2vw;
    margin: 1vw;
    padding: 1.5vw !important;
  }
  .checkbox-container > label {
    font-size: 2vw;
  }
  .checkbox-container.hebrew > label {
    font-size: 2.5vw;
  }
  #lectures-book-container .top-btn-container > button {
    font-size: 4vw;
    width: 5em;
  }
  #lectures-book-container .form-container {
    width: 93.6vw;
    height: 130vw;
    margin-bottom: 8vw;
  }
  .form-select {
    font-size: 3vw !important;
    height: max-content;
  }
  #lectures-book-container .form-container-2 {
    width: 93.6vw;
    height: 130vw;
    margin-bottom: 8vw;
  }

  #lectures-book-container .form-container-3 {
    width: 93.6vw;
    height: 130vw;
    margin-bottom: 8vw;
  }

  #lectures-book-container .form-header {
    font-size: 4vw;
    padding-bottom: 0.6vw !important;
    margin-bottom: 0px !important;
    padding-bottom: 5vw !important;
  }
  /* #lectures-book-container .radio-container {
    flex-direction: column;
  }
  #lectures-book-container .radio-container > input:not(:first-child) {
    margin-left: 0%;
  } */
  #lectures-book-container .radio-container > input {
    /* width: 2.5vw; */
    height: auto;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
  #lectures-book-container .radio-container.hebrew > input {
    margin-left: 0px !important;
  }
  #lectures-book-container .radio-container > label {
    margin-left: 2%;
    margin-bottom: 0px !important;
    padding-bottom: 5vw !important;
    font-size: 4vw;
  }
  #lectures-book-container .event-time-input-container {
    flex-direction: column !important;
  }

  #lectures-book-container .event-time-input-container > input {
    width: 100%;
    height: auto;
    font-size: 3vw;
  }
  #lectures-book-container .event-time-input-container > input[type="date"] {
    width: 100%;
    height: auto;
    font-size: 3vw;
  }
  #lectures-book-container .event-time-input-container > input[type="time"] {
    width: 100%;
    height: auto;
    font-size: 3vw;
  }
  #lectures-book-container .lecture-input-style {
    font-size: 3vw;
    padding-top: 1vw;
  }

  #lectures-book-container .input-padding {
    padding-top: 2vw;
    padding-bottom: 2vw;
  }
  #lectures-book-container .input-fs {
    width: 100%;
    font-size: 3vw;
  }

  #lectures-book-container .lecture-textarea-style {
    font-size: 3vw !important;
  }

  #lectures-book-container .lecture-submit-btn {
    margin-right: 15%;
    font-size: 3vw !important;
    padding-left: 3vw !important;
    padding-right: 3vw !important;
    margin-top: 2vw;
    transform: translateX(-10vw);
  }

  #lectures-book-container .final-form-header {
    font-size: 6vw !important;
    padding-bottom: 2vw;
  }
  #lectures-book-container .final-form-text {
    font-size: 3vw !important;
  }
  #lectures-book-container .eilon-span-container {
    font-size: 4vw !important;
    text-align: right;
  }
  #lectures-book-container .sub-header {
    font-size: 5vw;
  }
  #lectures-book-container .top-btn-container {
    gap: 2vw;
    font-size: 3vw !important;
    margin-bottom: 10vw;
  }
  .course-hebrew {
    transform: translateX(0vw);
  }

  .seminar-hebrew {
    transform: translateX(-1.5vw);
  }
  #lectures-book-container .lecture-submit-btn.hebrew {
    transform: translateX(24vw) !important;
  }
  #lectures-book-container .lecture-submit-btn {
    transform: translateX(-10vw) !important;
  }
  .seminar-radio-input {
    transform: translateX(-1vw) scale(1.5) !important;
  }
}

#lectures-book-container .input-fs {
  width: 100%;
}

#lectures-book-container .lecture-textarea-style {
  font-size: 1.7vw;
}

#lectures-book-container .lecture-textarea-style:not(:last-child) {
  margin-bottom: 0.4vw;
}
#lectures-book-container .lecture-submit-btn.hebrew {
  float: left;
  transform: translateX(13vw);
}
#lectures-book-container .lecture-submit-btn {
  align-self: flex-end;
  /* margin-right: 15%; */
  font-size: 1vw;
  padding-left: 2vw;
  padding-right: 2vw;
  border: 1px solid black;
  background-color: #ff746b;
  /* margin-top: 2vw; */
  float: right;
  transform: translateX(-8vw);
}

#lectures-book-container .final-form-header {
  font-size: 2.5vw;
  padding-bottom: 2vw;
  padding-left: 1vw;
  font-family: "Antipasto Pro Regular";
}
#lectures-book-container .final-form-header.hebrew {
  font-family: Rubik;
  font-weight: 600;
}
#lectures-book-container .final-form-text {
  font-size: 1.5vw;
  font-family: "Nunito";
  font-weight: 600;
  padding-left: 2vw;
}
.hebrew {
  font-family: Rubik;
}
#lectures-book-container .final-form-text.hebrew {
  font-family: Rubik;
}
#lectures-book-container .eilon-span {
  font-style: italic;
}

#lectures-book-container .eilon-span-container {
  display: flex;
  flex-direction: row;
  font-size: 1.5vw;
  font-family: "Nunito";
  font-weight: 600;
  width: 100%;
  justify-content: flex-end;
}
#lectures-book-container .eilon-span-container.hebrew {
  justify-content: flex-start;
}

#lectures-book-container .event-form-final-container {
  align-items: left;
  padding-left: 2vw;
  margin-left: 15%;
  margin-right: 15%;
}
.event-form-final-container.hebrew {
  align-items: right;
  text-align: right;
}
